@charset "utf-8";

@use "../_colors";
@use "../_text";
@use "../mixin/_mixin";


/* ------------------------------------

icon

------------------------------------ */


/*  p-item__icons
*----------------------------------- */

.p-item__icons .icon {
  display: inline-block;
  font-size: 12px;
  font-weight: 600;
  color: colors.$light-text;
  text-align: center;
  padding: 2px 8px 3px;
  line-height: 1.4;
  margin-bottom: 2px;
  border-radius: 4px;
  @media print, screen and (max-width: 760px) {
    padding: 2px 8px 3px;
    font-size: 3.2vw;
  }
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
    padding: 0 8px 4px;
  }
}

.p-item__icons:empty {
  margin-bottom: 0;
}



/*  new icon
*----------------------------------- */

.new {
  position: relative;
  &:after {
    content: "NEW";
    display: block;
    position: absolute;
    z-index: 1000;
    top: 10px;
    left: 10px;
    font-family: text.$font-new;
    font-size: 12px;
    text-align: center;
    font-style: normal;
    font-weight: 600;
    line-height: 44px;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    color: colors.$new-font-color;
    background: colors.$new-background;
    @media print, screen and (max-width: 760px) {
      font-size: 2vw;
      top: 1.5vw;
      left: 1.5vw;
      width: 10vw;
      height: 10vw;
      line-height: 10vw;
    }
  }
}

.l-system-detail .new:after {
  font-size: 16px;
  line-height: 60px;
  width: 60px;
  height: 60px;
  @media print, screen and (max-width: 760px) {
    font-size: 12px;
    line-height: 48px;
    width: 48px;
    height: 48px;
  }
}



/*  p-target_blank icon
*----------------------------------- */

.p-target_blank a span {
  position: relative;
  &::after {
    position: absolute;
    top: 4px;
    right: -16px;
    bottom: 0;
    z-index: 1000;
    display: block;
    width: 9px;
    height: 6px;
    margin: auto;
    content: "";
    background: url("../images/cmn/icon_link.svg") center/contain no-repeat;
  }
}
