@charset "utf-8";

@use "../_colors";
@use "../_text";
@use "../mixin/_mixin";

/* ------------------------------------

common（TOP含む全ページ）

------------------------------------ */

img {
  width: 100%;
}
iframe {
  max-width: 100%;
  height: auto;
  aspect-ratio: 16 / 9;
  @media print, screen and (max-width: 760px) {
    width: 100%;
  }
}

/*  l-wrapper
*----------------------------------- */

.l-wrapper {
  position: relative;
  overflow-x: hidden;
}

/*  l-main
*----------------------------------- */

.l-main {
  padding-bottom: 120px;
  @media print, screen and (max-width: 760px) {
    padding-bottom: 16%;
  }
}

/*  fadeInAnim
*----------------------------------- */

.fadeInAnim {
  opacity: 0;
}
.fadeInAnim.visible {
  @media screen and (min-width: 761px) {
    animation: fadeInUp 0.8s ease-out forwards;
  }
  @media screen and (max-width: 760px) {
    animation: fadeInUp_sp 0.8s ease-out forwards;
  }
}
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 50px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeInUp_sp {
  from {
    opacity: 0;
    transform: translate3d(0, 30px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

/*  tel
*----------------------------------- */

.p-tel a {
  @media print, screen and (min-width: 761px) {
    pointer-events: none;
  }
}

/*  bg_movie
*----------------------------------- */

.bg_movie {
  position: fixed;
  z-index: -2;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@media screen and (max-width: 760px) and (orientation: portrait) {
  .modaal-inner-wrapper {
    width: 92vw;
  }
}

/*  slick-dots
*----------------------------------- */

.slick-dots li {
  width: 10px;
  height: 10px;
  margin: 0 8px;
  background: colors.$slick-dots;
  &.slick-active {
    background: colors.$slick-dots-active;
  }
}

/*  horizontal
*----------------------------------- */

.horizontal {
  margin-bottom: 50px;
  opacity: 1;
  text-align: center;
  position: relative;
  @media print, screen and (max-width: 760px) {
    margin-bottom: 5%;
    padding: 0;
    border: none;
  }
  li {
    display: inline-block;
    margin: 0 7px 7px;
    opacity: 1;
    background: colors.$horizontal-background;
    @media print, screen and (max-width: 760px) {
      display: block;
      float: left;
      width: 50%;
      margin: 0 7px;
      opacity: 1;
      margin: 0;
      border-right: 1px solid colors.$light-border;
      border-bottom: 1px solid colors.$light-border;
      font-size: 12px;
    }
    a {
      color: colors.$horizontal-text;
      display: block;
      padding: 7px 15px;
      text-align: center;
      @media print, screen and (max-width: 760px) {
        padding: 10px 5px;
        font-size: 12px;
      }
    }
  }
  li.active {
    background: colors.$horizontal_active-background;
    a {
      color: colors.$horizontal_active-text;
    }
  }
  li:nth-child(2n) {
    @media print, screen and (max-width: 760px) {
      border-right: none;
    }
  }
  input {
    display: none;
    height: 100%;
  }
  label {
    display: block;
    height: 100%;
    padding: 7px 10px;
    text-align: center;
  }
  label:hover {
    background: colors.$horizontal_active-background;
    border-radius: 4px;
    color: colors.$horizontal_active-text;
  }
  input[type="radio"]:checked + label {
    background: colors.$horizontal_active-background;
    border-radius: 4px;
    color: colors.$horizontal_active-text;
  }
}

.horizontal.border {
  border-top: 1px solid colors.$horizontal-border;
  border-bottom: 1px solid colors.$horizontal-border;
  padding: 10px 0 3px;
}

/*  l-table
*----------------------------------- */

.l-table {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 0.3%;
  dt {
    background: colors.$accent02-background;
    color: colors.$light-text;
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    margin-bottom: 6px;
    @media print, screen and (max-width: 760px) {
      width: 28%;
      font-size: 3.6vw;
      text-align: center;
    }
  }
  dd {
    background: colors.$dim-background;
    width: 79.5%;
    padding: 10px 16px;
    font-weight: 400;
    margin-bottom: 6px;
    @media print, screen and (max-width: 760px) {
      width: 70.5%;
      font-size: 3.5vw;
      line-height: 1.5;
    }
    a {
      @media print, screen and (max-width: 760px) {
        text-decoration: underline;
      }
    }
  }
}

/*  l-interview
*----------------------------------- */
.page-top .l-interview,
.page-cmn .l-interview {
  margin-bottom: 100px;
  @media print, screen and (max-width: 760px) {
    margin-bottom: 18vw;
  }
  &__container {
    max-width: 90%;
    margin: 0 auto;
    @media print, screen and (min-width: 761px) {
      display: flex;
      justify-content: center;
      align-items: flex-start;
    }
    @media print, screen and (max-width: 760px) {
      max-width: 100%;
    }
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
    gap: 50px 30px;
    width: calc(100% - 255px - 30px);
    @media print, screen and (max-width: 760px) {
      width: 100%;
      margin-bottom: 10vw;
    }
    .slick-prev,
    .slick-next {
      top: 440px;
      width: 46px;
      height: 46px;
    }
    .slick-prev {
      background: url(../images/cmn/prev.png);
      background-size: 100%;
      left: -290px;
    }
    .slick-next {
      background: url(../images/cmn/next.png);
      background-size: 100%;
      left: -225px;
    }
  }
  .p-header {
    width: 260px;
    margin-right: 25px;
    margin-top: 90px;
    text-align: left;
    @media print, screen and (max-width: 760px) {
      width: 100%;
      margin: 0 auto;
      text-align: center;
    }
    &__title {
      font-size: 48px;
      color: colors.$gray;
      @media print, screen and (max-width: 760px) {
        font-size: 13vw;
        line-height: 1;
      }
    }
    &__subtitle {
      font-weight: 600;
      margin-top: 10px;
      @media print, screen and (max-width: 760px) {
        margin-top: 1vw;
      }
    }
    &__catch {
      font-size: 35px;
      font-weight: 600;
      margin-top: 20px;
      line-height: 1.4;
      @media print, screen and (max-width: 760px) {
        font-size: 9.2vw;
        margin-top: 5vw;
        text-align: center;
      }
    }
    &__text {
      margin-top: 15px;
      margin-bottom: 35px;
      @media print, screen and (max-width: 760px) {
        margin-top: 3vw;
        margin-bottom: 7vw;
        text-align: center;
      }
    }
  }
  .p-item {
    @media print, screen and (min-width: 761px) {
      width: 23%;
      margin-right: 36px;
    }
    @media print, screen and (max-width: 760px) {
      margin: 0 3vw;
    }
    &__photo {
      margin-bottom: 20px;
      @media print, screen and (max-width: 760px) {
        margin-bottom: 5vw;
      }
    }
    &__title {
      font-size: 25px;
      font-weight: 600;
      margin-bottom: 10px;
      line-height: 1.2;
      @media print, screen and (max-width: 760px) {
        font-size: 5.5vw;
        margin-bottom: 2vw;
      }
    }
    &__text {
      font-size: 17px;
      font-weight: 600;
      padding-bottom: 12px;
      margin-bottom: 12px;
      border-bottom: 1px solid colors.$dark-text;
      @media print, screen and (max-width: 760px) {
        font-size: 4.3vw;
        padding-bottom: 4vw;
        margin-bottom: 4vw;
      }
    }
    &__info {
      font-size: 14px;
      @media print, screen and (max-width: 760px) {
        font-size: 3.6vw;
      }
    }
  }
  .c-button.simple {
    @media print, screen and (max-width: 760px) {
      margin: 0 auto;
    }
  }
}

.page-top .p-header {
  &.interview {
    display: none;
  }
}
.page-interview-detail .p-header {
  &.top {
    display: none;
  }
}

/*  l-other-contents
*----------------------------------- */

.l-other-contents {
  background: colors.$dim-background;
  padding: 60px 0 70px;
  margin: 0;
  @media print, screen and (max-width: 760px) {
    padding: 14vw 0 17vw;
  }
  &__container {
    max-width: 1200px;
    margin: 0 auto;
    @media print, screen and (max-width: 760px) {
      width: 85%;
    }
  }
  .p-header {
    text-align: center;
    max-width: 90%;
    margin: 0 auto 25px;
    @media print, screen and (max-width: 760px) {
      margin-bottom: 5vw;
    }
  }
  .p-header__title {
    font-size: 60px;
    color: inherit;
    @media print, screen and (max-width: 760px) {
      font-size: 10vw;
      color: inherit;
    }
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    @media print, screen and (max-width: 760px) {
      gap: 4vw;
    }
  }
  li {
    width: 320px;
    @media print, screen and (max-width: 760px) {
      width: 100%;
    }
    a {
      display: block;
      font-size: 20px;
      font-weight: text.$font-base-weight-medium;
      padding: 22px 24px;
      text-align: center;
      background: colors.$light-background;
      @media print, screen and (max-width: 760px) {
        font-size: 5.1vw;
        padding: 5.5vw 0;
      }
    }
  }
}

.page-cmn {
  .l-other-contents {
    .p-item {
      display: block;
      &.crosstalk,
      &.about,
      &.recruit {
        display: none;
      }
    }
  }
}
// ↓ページに合わせて変える
.page-profile {
  .l-other-contents {
    .p-item {
      display: none;
      &.crosstalk,
      &.about,
      &.recruit {
        display: block;
      }
    }
  }
}

//=================================
//  画像アニメーション
//=================================

.js-sa {
  &.-img {
    position: relative;
    overflow: hidden;
    opacity: 1;
    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: #fff;
      z-index: 1;
      transition: 0.1s;
      transition-delay: 0.8s;
    }
    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 1;
      transform: scaleX(0);
      transform-origin: top left;
      background: #fff;
    }
    &.is-show {
      &::before {
        transition-delay: 0.4s;
        background: none;
      }
      &::after {
        animation: saImg 0.8s cubic-bezier(0.43, 0.05, 0.17, 1) forwards;
        @keyframes saImg {
          0% {
            transform-origin: top left;
            transform: scaleX(0);
          }
          48% {
            transform: scaleX(1);
          }
          52% {
            transform: scaleX(1);
          }
          100% {
            transform: scaleX(1) translate(100%);
          }
        }
      }
    }
  }
}

//=================================
//  READ MORE
//=================================

.read-more {
  padding-right: 27px;
  font-size: 1.2rem;
  font-weight: 400;
  font-family: text.$font01;
  line-height: 1;
  display: block;
  width: fit-content;
  position: relative;
  @include mixin.sp {
    padding-right: mixin.vw(27);
    @include mixin.fz(12);
  }
  &::before {
    position: absolute;
    top: -3px;
    right: 0;
    bottom: 0;
    width: 21px;
    height: 5px;
    background: url("../images/cmn/arrow.svg") center/contain no-repeat;
    display: block;
    margin: auto;
    content: "";
    filter: invert(8%) sepia(69%) saturate(523%) hue-rotate(196deg) brightness(96%) contrast(86%);
    @include mixin.sp {
      top: -#{mixin.vw(3)};
      width: mixin.vw(21);
      height: mixin.vw(5);
    }
  }
}

.read-more--white {
  color: #fff;
  &::before {
    filter: brightness(0) invert(1);
  }
}

//=================================
//  流れる文字
//=================================

.flow-text {
  background-color: colors.$accent;
  color: #fff;
  height: 63px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  overflow: hidden;
  @include mixin.sp {
    height: mixin.vw(42);
  }
  > div {
    display: flex;
    width: 100vw;
  }
  span {
    flex: 0 0 auto;
    font-weight: 400;
    font-family: text.$font01;
    font-size: 2.2rem;
    letter-spacing: 0.05em;
    white-space: nowrap;
    line-height: 1em;
    display: inline-block;
    padding: 0 0.5em;
    @include mixin.sp {
      @include mixin.fz(16);
    }
    &:nth-child(odd) {
      animation: loop 50s -25s linear infinite;
    }
    &:nth-child(even) {
      animation: loop2 50s linear infinite;
    }
    @keyframes loop {
      0% {
        transform: translateX(100%);
      }
      to {
        transform: translateX(-100%);
      }
    }

    @keyframes loop2 {
      0% {
        transform: translateX(0);
      }
      to {
        transform: translateX(-200%);
      }
    }
  }
  &--black {
    background-color: #222438;
  }
}


//=================================
//  PEOPLE
//=================================

.people {
  margin-bottom: 110px;
  @include mixin.sp {
    margin-bottom: mixin.vw(74);
  }
}

.interview {
  padding: 118px 30px 0;
  margin-bottom: 100px;
  padding-top: 118px;
  @include mixin.sp {
    margin-bottom: mixin.vw(84);
    padding: mixin.vw(67) 0 0;
  }
}

.interview__wrap {
  padding-top: 166px;
  display: flex;
  justify-content: center;
  gap: 40px;
  width: fit-content;
  margin: 0 auto;
  background: url("../images/top/txt_people.png") left top no-repeat;
  background-size: 1070px auto;
  @include mixin.sp {
    padding-top: mixin.vw(100);
    gap: mixin.vw(32);
    width: 100%;
    background: url("../images/top/sp/txt_people.png") center top/contain no-repeat;
    flex-flow: column;
  }
}

.interview__txt {
  @include mixin.pc {
    min-width: 312px;
    padding-top: 97px;
  }
  @include mixin.sp {
    margin: 0 mixin.vw(20);
  }
  p {
    line-height: 2;
  }
}

.interview__catch {
  font-size: 4.2rem;
  line-height: calc(56 / 42);
  font-family: text.$font-tit;
  font-weight: 400;
  display: block;
  margin-bottom: 20px;
  @include mixin.sp {
    margin-bottom: mixin.vw(10);
    @include mixin.fz(42);
  }
}

.interview__ttl {
  width: fit-content;
  font-size: 1.7rem;
  font-weight: bold;
  line-height: 1;
  background-color: colors.$black;
  color: #fff;
  padding: 0.35em 0.85em;
  margin-bottom: 27px;
  @include mixin.sp {
    margin-bottom: mixin.vw(24);
    @include mixin.fz(16);
  }
}

#interview-list-arrow {
  display: flex;
  gap: 19px;
  margin-top: 24px;
  .slick-arrow {
    position: relative;
    margin: 0;
    transform: none;
    border: 2px solid colors.$black;
    border-radius: 50%;
    width: 47px;
    height: 47px;
    background: none;
    top: 0;
    left: 0;
    right: 0;
    transition: 0.3s;
    &::before {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: block;
      box-sizing: border-box;
      width: 8px;
      height: 6px;
      margin: auto;
      content: "";
      transform: translateX(25%);
      border: 6px solid transparent;
      border-left: 8px solid colors.$black;
      transition: 0.3s;
    }
    &:hover {
      border-color: colors.$accent;
      &::before {
        border-left: 8px solid colors.$accent;
      }
    }
  }
  .slick-prev {
    &::before {
      transform: translateX(-25%) scaleX(-1);
    }
  }
}

.interview-list {
  overflow: hidden;
  @include mixin.pc {
    max-width: 970px;
  }
  @include mixin.sp {
    width: 100%;
  }
}

#interview-list__slider {
  @include mixin.pc {
    width: calc(100% + 32px);
  }
}

.interview-list__item {
  @include mixin.pc {
    margin-right: 32px;
  }
  @include mixin.sp {
    margin: 0 mixin.vw(12);
    width: mixin.vw(233);
  }
  a {
    display: block;
    @include mixin.pc {
      max-width: 301px;
    }
    &:hover {
      opacity: 1;
    }
  }
}

.interview-list__img {
  background-color: #fff;
  margin-bottom: 14px;
  @include mixin.sp {
    margin-bottom: mixin.vw(12);
  }
  img {
    object-fit: cover;
    aspect-ratio: 301/404;
    transition: 0.3s;
    height: auto;
    @include mixin.sp {
      aspect-ratio: 233/313;
    }
  }
}

.interview-list__txt {
  .interview-list__copy {
    font-size: 2.3rem;
    font-weight: bold;
    line-height: calc(34 / 23);
    -webkit-line-clamp: 3;
    max-height: 100px;
    overflow: hidden;
    @include mixin.sp {
      @include mixin.fz(17);
      line-height: calc(26 / 17);
    }
  }
}

.interview-list__name {
  display: block;
  font-size: 1.8rem;
  font-weight: 500;
  margin-top: 0.8em;
  margin-bottom: 0.5em;
  padding-bottom: 0.5em;
  border-bottom: 1px solid colors.$black;
  max-height: 65px;
  overflow: hidden;
  @include mixin.sp {
    @include mixin.fz(12);
    max-height: 11vw;
  }
}

.interview-list__department {
  font-weight: 500;
  font-size: 1.5rem;
  @include mixin.sp {
    @include mixin.fz(10);
  }
  &.p-item__icons .icon {
    background: none !important;
    color: inherit;
    padding: 0;
    font-size: 1.5rem;
  }
}

.interview__read-more {
  margin-top: mixin.vw(24);
  display: flex;
  justify-content: center;
  a {
    display: block;
    padding: mixin.vw(10);
  }
}

.people-link {
  margin-bottom: 100px;
  padding: 87px 30px;
  background-color: colors.$black;
  @include mixin.sp {
    padding: mixin.vw(60) mixin.vw(37);
    margin-bottom: mixin.vw(85);
  }
}

.people-link-list {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  gap: 42px;
  @include mixin.sp {
    gap: mixin.vw(31);
    flex-flow: column;
  }
}

.people-link-list__item {
  flex: 1;
  background-color: #fff;
  min-height: 252px;
  @include mixin.sp {
    min-height: mixin.vw(181);
  }
  a {
    display: block;
    box-sizing: border-box;
    height: 100%;
    padding: 44px 28px;
    position: relative;
    @include mixin.sp {
      padding: mixin.vw(27) mixin.vw(23) mixin.vw(38);
    }
    &::before {
      position: absolute;
      right: 22px;
      bottom: 21px;
      width: 35px;
      height: 9px;
      display: block;
      margin: auto;
      content: "";
      background: url("../images/cmn/arrow.svg") center/contain no-repeat;
      filter: invert(8%) sepia(69%) saturate(523%) hue-rotate(196deg) brightness(96%) contrast(86%);
      @include mixin.sp {
        right: mixin.vw(19);
        bottom: mixin.vw(19);
        width: mixin.vw(30);
        height: mixin.vw(7);
      }
    }
  }
  p {
    line-height: 2;
  }
}

.people-link-list__ttl {
  display: block;
  font-size: 3.6rem;
  font-weight: bold;
  width: fit-content;
  position: relative;
  padding-bottom: 3px;
  margin-bottom: 7px;
  &::before {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 4px;
    width: 0;
    background-color: colors.$accent;
    display: block;
    margin: auto;
    content: "";
    transform-origin: left;
    transition: 0.3s;
  }
  a:hover & {
    &::before {
      width: 100%;
    }
  }
}

.people-column {
  display: flex;
  max-width: 1200px;
  margin: 0 auto;
  justify-content: center;
  gap: 58px;
  @include mixin.pc {
    box-sizing: content-box;
    padding: 0 30px;
  }
  @include mixin.sp {
    gap: mixin.vw(56);
    margin: 0 mixin.vw(20);
    flex-flow: column;
  }
}

.people-column__item {
  @include mixin.pc {
    &:nth-of-type(even) {
      padding-top: 91px;
    }
  }
  a {
    display: block;
    &:hover {
      opacity: 1;
    }
  }
}

.people-column__img {
  position: relative;
  margin-bottom: 18px;
  @include mixin.sp {
    margin-bottom: mixin.vw(22);
  }
  figcaption {
    z-index: 2;
    top: 0;
    right: 0;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 51px;
    line-height: 1;
    padding: 0 10px;
    box-sizing: border-box;
    min-width: 166px;
    color: #fff;
    font-size: 2rem;
    font-weight: bold;
    background-color: colors.$accent;
    transition: 0.3s;
    @include mixin.sp {
      min-width: mixin.vw(127);
      height: mixin.vw(39);
      @include mixin.fz(15);
    }
  }
  img {
    object-fit: cover;
    aspect-ratio: 538/297;
    height: auto;
    @include mixin.sp {
      aspect-ratio: 350/193;
    }
  }
  a:hover & {
    figcaption {
      background-color: colors.$black;
    }
    img {
      opacity: 1;
    }
  }
}

.people-column__txt {
  p {
    line-height: 2;
  }
}

.people-column__ttl {
  margin-bottom: 20px;
  display: block;
  font-family: text.$font-tit;
  font-size: 5rem;
  line-height: calc(64 / 50);
  font-weight: 400;
  @include mixin.sp {
    margin-bottom: mixin.vw(10);
    line-height: calc(51 / 36);
    @include mixin.fz(36);
  }
}

.people-column__read-more {
  margin-top: 24px;
  @include mixin.sp {
    margin-top: mixin.vw(24);
  }
}
