@charset "utf-8";

@use "../_colors";
@use "../_text";
@use "../mixin/_mixin";

/* ------------------------------------

header

------------------------------------ */

.l-header {
  width: 100%;
  position: fixed;
  z-index: 10000;
  font-size: 15px;
  font-weight: 600;
  top: 0;
  height: 75px;
  display: flex;
  align-items: center;
}

.l-header__container {
  width: 100%;
  height: 100%;
  padding: 0 0 0 20px;
  margin: 0 auto;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include mixin.sp {
    padding: 0 0 0 mixin.vw(12);
  }
}

.header__logo {
  margin-right: auto;
  a {
    display: flex;
    align-items: center;
    gap: 9px;
    @include mixin.sp {
      gap: mixin.vw(7);
    }
  }
  img {
    width: 154px;
    height: auto;
    @include mixin.sp {
      width: mixin.vw(121);
    }
  }
  p {
    font-size: 1.1rem;
    line-height: 1.2;
    @include mixin.sp {
      @include mixin.fz(10);
    }
  }
}

.header-btn {
  display: flex;
  gap: 8px;
}

.header-btn__item {
  min-width: 80px;
  body.nav-active & {
    background-color: #fff;
  }
  a {
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
    box-sizing: border-box;
    border: 1px solid colors.$black;
    font-size: 1.2rem;
    font-weight: bold;
    line-height: 1;
    transition: 0.3s;
    &:hover {
      opacity: 1;
      color: #fff;
      background-color: colors.$accent;
      border-color: colors.$accent;
    }
  }
  &--line {
    min-width: 127px;
    a {
      color: #fff;
      background-color: colors.$black;
    }
  }
  &--entry {
    min-width: 122px;
    a {
      line-height: 2;
      letter-spacing: 0.12em;
      font-size: 1.7rem;
      font-family: text.$font01;
      color: #fff;
      background-color: colors.$sub01;
    }
  }
}
