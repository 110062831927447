@charset "utf-8";

@use "../_colors";
@use "../_text";
@use "../mixin/_mixin";

//=================================
//  footer-link
//=================================

.footer-link {
  padding: 69px 30px 89px;
  background-color: colors.$accent;
  @include mixin.sp {
    padding: mixin.vw(51) mixin.vw(20) mixin.vw(63);
  }
}

.footer-link__ttl {
  margin-bottom: 29px;
  color: #fff;
  text-align: center;
  line-height: 1;
  font-size: 1.5rem;
  font-weight: 500;
  @include mixin.sp {
    @include mixin.fz(16);
  }
  span {
    margin-bottom: 10px;
    display: block;
    font-size: 6rem;
    font-weight: bold;
    font-family: text.$font01;
    letter-spacing: 0.05em;
    @include mixin.sp {
      @include mixin.fz(47);
    }
  }
}

.footer-link__btn {
  display: flex;
  gap: 20px;
  max-width: 1200px;
  margin: 0 auto;
  @include mixin.sp {
    margin: 0 mixin.vw(10);
    gap: mixin.vw(14);
    flex-flow: column;
  }
  li {
    flex: 1;
  }
  a {
    padding: 5px;
    box-sizing: border-box;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 74px;
    font-size: 1.9rem;
    font-weight: bold;
    @include mixin.sp {
      padding: mixin.vw(5);
      height: mixin.vw(74);
      @include mixin.fz(20);
    }
  }
  &--round {
    a {
      border-radius: 10px;
      @include mixin.sp {
        border-radius: mixin.vw(10);
      }
    }
  }
}

//=================================
//  WEB MAGAZINE
//=================================

.footer-magazine {
  a {
    display: block;
    color: #fff;
    background-color: #222438;
    padding: 78px 30px 80px;
    transition: 0.3s;
    @include mixin.sp {
      padding: mixin.vw(62) mixin.vw(20) mixin.vw(50);
    }
    &:hover {
      opacity: 1;
      background-color: #1c2050;
      img {
        opacity: 1;
      }
    }
  }
}

.footer-magazine__wrap {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  gap: 39px;
  @include mixin.pc {
    flex-flow: row-reverse;
  }
  @include mixin.sp {
    flex-flow: column;
  }
}

.footer-magazine__img {
  img {
    height: auto;
    @include mixin.pc {
      max-width: 561px;
    }
  }
}

.footer-magazine__txt {
  @include mixin.pc {
    width: 50%;
  }
  p {
    font-weight: 500;
    line-height: 2;
  }
}

.footer-magazine__caption {
  font-family: text.$font01;
  font-weight: bold;
  font-size: 5.9rem;
  line-height: 1;
  display: block;
  margin-bottom: 17px;
  letter-spacing: 0.05em;
  @include mixin.sp {
    margin-bottom: mixin.vw(17);
    @include mixin.fz(43);
  }
  span {
    margin-bottom: -5px;
    display: block;
    font-size: 9.3rem;
    @include mixin.sp {
      margin-bottom: 0;
      @include mixin.fz(68);
    }
  }
}

.footer-magazine__ttl {
  margin-bottom: 20px;
  background-color: #fff;
  color: #222438;
  display: block;
  width: fit-content;
  line-height: 1;
  font-size: 1.7rem;
  font-weight: bold;
  padding: 0.35em 0.75em;
  @include mixin.sp {
    padding: 0.35em 0.5em;
    margin-bottom: mixin.vw(16);
    @include mixin.fz(16);
  }
}

.footer-magazine__read-more {
  margin-top: 36px;
  @include mixin.sp {
    margin-top: mixin.vw(24);
  }
}

//=================================
//  LINE
//=================================

.footer-line {
  padding: 53px 30px 57px;
  background-color: #eaeaea;
  @include mixin.sp {
    padding: mixin.vw(45) mixin.vw(20) mixin.vw(68);
  }
  p {
    font-weight: 500;
    line-height: 2;
    @include mixin.pc {
      text-align: center;
    }
  }
}

.footer-line__ttl {
  margin-bottom: 16px;
  text-align: center;
  font-weight: bold;
  font-size: 3.7rem;
  line-height: calc(50 / 37);
  @include mixin.sp {
    margin-bottom: mixin.vw(13);
    line-height: calc(30 / 22);
    @include mixin.fz(22);
  }
  &::before {
    display: block;
    width: 66px;
    height: 63px;
    margin: 0 auto 16px;
    content: "";
    background: url("../images/cmn/icon_line.svg") center/contain no-repeat;
    @include mixin.sp {
      width: mixin.vw(66);
      height: mixin.vw(63);
      margin: 0 auto mixin.vw(13);
    }
  }
}

.footer-line__btn {
  margin-top: 24px;
  @include mixin.sp {
    margin: mixin.vw(24) mixin.vw(20) 0;
  }
  a {
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 5px;
    height: 59px;
    color: #fff;
    font-size: 2.1rem;
    font-weight: bold;
    background-color: #06c755;
    @include mixin.pc {
      max-width: 316px;
    }
    @include mixin.sp {
      padding: mixin.vw(5);
      height: mixin.vw(59);
      @include mixin.fz(21);
    }
  }
}

//=================================
//  footer entry
//=================================

#footer {
  @include mixin.sp {
    margin-bottom: mixin.vw(64);
  }
}

.footer-entry__contents {
  padding: 57px 0 218px;
  background: url("../images/cmn/bg_footer_entry.jpg") center bottom/cover no-repeat;
  @include mixin.sp {
    background: url("../images/cmn/sp/bg_footer_entry.jpg") center bottom/contain no-repeat;

    padding: mixin.vw(40) mixin.vw(20) mixin.vw(260);
  }
  > p {
    text-align: center;
    font-size: 2.3rem;
    font-weight: bold;
    line-height: calc(47 / 23);
    @include mixin.sp {
      @include mixin.fz(21);
      line-height: calc(41 / 21);
    }
  }
}

.footer-entry__ttl {
  margin-bottom: 18px;
  text-align: center;
  font-family: text.$font-tit;
  font-weight: 400;
  font-size: 4.9rem;
  @include mixin.sp {
    margin-bottom: mixin.vw(24);
    line-height: calc(40 / 30);
    @include mixin.fz(30);
  }
  span {
    margin-bottom: 33px;
    font-size: 14.3rem;
    display: block;
    line-height: 1;
    @include mixin.sp {
      margin-bottom: mixin.vw(24);
      line-height: 1.2;
      text-indent: -0.8em;
      margin-left: 0.8em;
      @include mixin.fz(84);
    }
  }
}

.footer-entry-btn {
  margin: 56px auto 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 17px;
  max-width: 1200px;
  @include mixin.sp {
    gap: mixin.vw(14);
    margin: mixin.vw(34) mixin.vw(20) 0;
    flex-flow: column;
  }
}

.footer-entry-btn__item {
  width: 100%;
  @include mixin.pc {
    max-width: 270px;
  }
  a {
    width: 100%;
    display: flex;
    height: 69px;
    padding: 0 10px;
    align-items: center;
    justify-content: center;
    background-color: colors.$sub01;
    font-size: 2.5rem;
    font-weight: bold;
    box-sizing: border-box;
    color: #fff;
    transition: 0.3s;
    @include mixin.sp {
      height: mixin.vw(55);
      @include mixin.fz(20);
    }
    &:hover {
      opacity: 1;
      background-color: #000;
      color: #fff;
      border-color: #000;
    }
  }
  &--contact {
    @include mixin.pc {
      max-width: 216px;
    }
    a {
      background-color: #fff;
      color: colors.$sub01;
      border: 2px solid colors.$sub01;
    }
  }
  &--reserve {
    @include mixin.pc {
      max-width: 334px;
    }
    a {
      background-color: colors.$accent;
    }
  }
}

//=================================
//  footer content
//=================================

.footer-content {
  font-family: text.$font-sub;
  box-sizing: border-box;
  background-color: #fff;
  padding: 77px 0 0;
  @include mixin.sp {
    padding: mixin.vw(70) mixin.vw(20) 0;
  }
}

.footer-logo {
  margin-bottom: 48px;
  text-align: center;
  @include mixin.sp {
    margin-bottom: mixin.vw(18);
  }
  a {
    display: inline-block;
  }
  img {
    margin: 0 auto 19px;
    height: auto;
    width: 180px;
    @include mixin.sp {
      margin: 0 auto mixin.vw(19);
      width: mixin.vw(180);
    }
  }
  p {
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 1;
    @include mixin.sp {
      @include mixin.fz(15);
    }
  }
}

.footer-info {
  margin: 0 auto 40px;
  padding: 17px 0;
  line-height: 1.5;
  border-top: 1px solid #cacaca;
  max-width: 1200px;
  display: flex;
  justify-content: center;
  align-items: center;
  @include mixin.pc {
    border-bottom: 1px solid #cacaca;
  }
  @include mixin.sp {
    flex-flow: column;
    margin: 0 mixin.vw(20) mixin.vw(24);
    padding: vw(20) 0 0;
    text-align: center;
  }
}

.footer-info__address {
  font-size: 1.4rem;
  font-weight: 400;
  @include mixin.pc {
    margin-right: 14px;
  }
  @include mixin.sp {
    margin-bottom: mixin.vw(10);
    @include mixin.fz(14);
  }
}

.footer-info__access {
  width: fit-content;
  @include mixin.pc {
    margin-right: 21px;
  }
  @include mixin.sp {
    margin: 0 auto mixin.vw(16);
  }
  a {
    background-color: #fff;
    padding: 3px 14px 3px 6px;
    font-size: 1rem;
    line-height: 1;
    display: block;
    position: relative;
    border: 1px solid colors.$black;
    &::after {
      position: absolute;
      top: 52%;
      right: 5px;
      transform: translateY(-50%);
      z-index: 2;
      width: 0;
      height: 0;
      border-width: 3px 0 3px 5px;
      border-style: solid;
      border-color: transparent transparent transparent colors.$black;
      content: "";
    }
  }
}

.footer-info__tel {
  @include mixin.sp {
    margin-bottom: mixin.vw(10);
    text-align: center;
  }
  a {
    font-size: 2.3rem;
    font-weight: 500;
    line-height: 1;
    @include mixin.pc {
      pointer-events: none;
    }
    @include mixin.sp {
      gap: mixin.vw(10);
      @include mixin.fz(23);
    }
    span {
      font-size: 1.6rem;
      @include mixin.sp {
        @include mixin.fz(16);
      }
    }
  }
  img {
    width: 35px;
    height: auto;
    filter: invert(33%) sepia(0%) saturate(2284%) hue-rotate(193deg) brightness(106%) contrast(84%);
    @include mixin.sp {
      width: mixin.vw(39);
    }
  }
}

.footer-nav {
  margin: 0 auto 100px;
  display: flex;
  justify-content: center;
  gap: 60px;
}

.footer-nav-list {
  dt {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 0.6em;
  }
  dd {
    padding-left: 5px;
    margin-bottom: 0.6em;
    font-size: 1.2rem;
    a {
      &::before {
        margin-right: 0.5em;
      }
    }
  }
  a {
    display: block;
    &::before {
      content: "-";
    }
    &:hover {
      color: colors.$accent;
    }
  }
}

.copyright {
  text-align: center;
  background-color: colors.$black;
  height: 53px;
  display: flex;
  align-items: center;
  justify-content: center;
  small {
    font-size: 1rem;
    font-weight: 400;
    display: block;
    color: #fff;
    letter-spacing: 0.069em;
    @include mixin.sp {
      @include mixin.fz(10);
    }
  }
}

/*  l-control : footer menu (SP)
*----------------------------------- */

.fixed-btn {
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  z-index: 999999;
}

.fixed-btn-list {
  display: flex;
  background-color: #fff;
}

.fixed-btn-list__item {
  flex: auto;
  a {
    display: flex;
    align-items: center;
    justify-content: center;
    height: mixin.vw(64);
    font-size: 1.7rem;
    font-weight: bold;
    box-sizing: border-box;
    border-top: 1px solid colors.$sub01;
  }
  &--entry {
    width: 66%;
    flex: none;
    a {
      letter-spacing: 0.113em;
      @include mixin.fz(32);
      font-weight: bold;
      font-family: text.$font01;
      color: #fff;
      background-color: colors.$sub01;
    }
  }
}

/*  .topcontrol
*----------------------------------- */

.topcontrol {
  display: block;
  width: 45px;
  height: 45px;
  background: colors.$color-cv;
  opacity: 1;
  position: relative;
  @media print, screen and (max-width: 760px) {
    display: none;
  }
}
.topcontrol::after {
  position: absolute;
  z-index: 10000;
  top: 50%;
  left: 50%;
  margin-top: -3px;
  margin-left: -6px;
  display: block;
  width: 12px;
  height: 12px;
  content: " ";
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  border-top: 1px #fff solid;
  border-left: 1px #fff solid;
}

/*  .l-footer-cookie
*----------------------------------- */

.l-footer-cookie {
  &.l-footer-cookie--close {
    display: none;
  }
  &__in {
    background: rgba(0, 0, 0, 0.7);
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 10000000;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.5s ease, visibility 0s 0.5s ease;
    &.js-load--on {
      opacity: 1;
      visibility: visible;
      transition: opacity 0.5s 1s ease, visibility 0s 1s ease;
    }
  }
  &__container {
    width: 1000px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    color: #fff;
    padding: 20px 0;
    @media print, screen and (max-width: 760px) {
      width: 100%;
      padding: 5.33333vw;
      display: block;
    }
  }
  &__text {
    width: 850px;
    font-size: 12px;
    line-height: 1.8;
    font-weight: normal;
    @media print, screen and (max-width: 760px) {
      width: 100%;
      font-size: 2.93333vw;
      line-height: 1.5;
    }
    a {
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }
  .l-footer-cookie__btn {
    width: 100px;
    cursor: pointer;
    @media print, screen and (max-width: 760px) {
      width: 53.33333vw;
      margin: 2.66667vw auto 0;
    }
    &.btn-03 {
      display: inline-block;
      padding: 15px 20px;
      font-size: 12px;
      line-height: 1.6;
      font-weight: bold;
      background: #f8f8f8;
      position: relative;
      text-align: center;
      color: #000;
      z-index: 1;
      transition: border-radius 0.3s ease;
      cursor: pointer;
      @media print, screen and (max-width: 760px) {
        display: block;
        padding: 1.6vw 6.66667vw;
        font-size: 3.2vw;
      }
    }
  }
}
