@charset "utf-8";

@use "../_colors";
@use "../_text";

/*!
 * jquery-drawer - A small CSS3 and jQuery plugin for app style drawer menu.
 * @version v2.4.0
 * @link    http://git.blivesta.com/drawer
 * @author  blivesta
 * @license MIT
 */
body.drawer-navbar.drawer-fixed {
  padding-top: 0;
}
.drawer-main {
  position: fixed;
  z-index: 999999;
  bottom: 0;
  overflow: hidden;
  height: 100%;
}
.drawer-left .drawer-main {
  left: -280px;
  -webkit-transition: -webkit-transform 0.6s cubic-bezier(0.19, 1, 0.22, 1);
  -o-transition: -o-transform 0.6s cubic-bezier(0.19, 1, 0.22, 1);
  transition: transform 0.6s cubic-bezier(0.19, 1, 0.22, 1);
  -webkit-transform: translate3d(-280px, 0px, 0px);
  transform: translate3d(-280px, 0px, 0px);
}
.drawer-left.drawer-open .drawer-main {
  left: 0;
  -webkit-transform: translate3d(0px, 0px, 0px);
  transform: translate3d(0px, 0px, 0px);
}
.drawer-right .drawer-main {
  right: -280px;
  -webkit-transition: -webkit-transform 0.6s cubic-bezier(0.19, 1, 0.22, 1);
  -o-transition: -o-transform 0.6s cubic-bezier(0.19, 1, 0.22, 1);
  transition: transform 0.6s cubic-bezier(0.19, 1, 0.22, 1);
  -webkit-transform: translate3d(280px, 0px, 0px);
  transform: translate3d(280px, 0px, 0px);
}
.drawer-right.drawer-open .drawer-main {
  right: 0;
  -webkit-transform: translate3d(0px, 0px, 0px);
  transform: translate3d(0px, 0px, 0px);
}
/* .drawer-top .drawer-main {
  width: 100%;
  height: auto;
  max-height: 100%;
  -webkit-transition: -webkit-transform 0.6s cubic-bezier(0.19, 1, 0.22, 1);
  -o-transition: -o-transform 0.6s cubic-bezier(0.19, 1, 0.22, 1);
  transition: transform 0.6s cubic-bezier(0.19, 1, 0.22, 1);
  -webkit-transform: translate3d(0, -100%, 0px);
  transform: translate3d(0, -100%, 0px);
} */
/* .drawer-top.drawer-open .drawer-main {
  -webkit-transform: translate3d(0, 0, 0px);
  transform: translate3d(0, 0, 0px);
} */
.drawer-top .drawer-main {//閉じたときに文字の残像が見えるのを調整
  width: 100%;
  height: auto;
  max-height: 100%;
  right: -280px;
  -webkit-transition: -webkit-transform 0.6s cubic-bezier(0.19, 1, 0.22, 1);
  -o-transition: -o-transform 0.6s cubic-bezier(0.19, 1, 0.22, 1);
  transition: transform 0.6s cubic-bezier(0.19, 1, 0.22, 1);
  -webkit-transform: translate3d(0, -100%, 0px);
  transform: translate3d(0, -100%, 0px);
}
.drawer-top.drawer-open .drawer-main {//閉じたときに文字の残像が見えるのを調整
  right: 0;
  -webkit-transform: translate3d(0, 0, 0px);
  transform: translate3d(0, 0, 0px);
}
.drawer-overlay {
  position: relative;
}
.drawer-left .drawer-hamburger {
  left: 0;
  -webkit-transform: translateX(0px) translateY(0px);
  -ms-transform: translateX(0px) translateY(0px);
  -o-transform: translateX(0px) translateY(0px);
  transform: translateX(0px) translateY(0px);
}
.drawer-left.drawer-open .drawer-hamburger {
  -webkit-transform: translateX(280px) translateY(0px);
  -ms-transform: translateX(280px) translateY(0px);
  -o-transform: translateX(280px) translateY(0px);
  transform: translateX(280px) translateY(0px);
}
.drawer-right .drawer-hamburger {
  right: 0;
  -webkit-transform: translateX(0px) translateY(0px);
  -ms-transform: translateX(0px) translateY(0px);
  -o-transform: translateX(0px) translateY(0px);
  transform: translateX(0px) translateY(0px);
}
.drawer-right.drawer-open .drawer-hamburger {
  -webkit-transform: translateX(-280px) translateY(0px);
  -ms-transform: translateX(-280px) translateY(0px);
  -o-transform: translateX(-280px) translateY(0px);
  transform: translateX(-280px) translateY(0px);
}
.drawer-top .drawer-hamburger {
  right: 0;
}
.drawer-top.drawer-open .drawer-hamburger {
  z-index: 99999999;
  right: 0;
}
.drawer-overlay-upper {
  position: fixed;
  z-index: 5200;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  display: none;
  background-color: rgba(255,255,255,1)
}
.drawer-open .drawer-overlay-upper {
  display: block !important;
}
.drawer-hamburger {
  border: 0;
  position: fixed;
  display: block;
  bottom: 0;
  padding: 10px 16px;
  width: 50px;
  height: 49px;
  background: colors.$hamburger-background;//_colorsから変更
  border: none;
  /* border-radius:50%; */
  z-index: 99999999;
  outline: 0;
  transition: 0.3s;
  -webkit-transition: 0.3s;
}
.drawer-open .drawer-hamburger {
  background: colors.$hamburger-background;//_colorsから変更
}
.drawer-hamburger.on, .naka .drawer-hamburger {
  bottom: 0;
  z-index: 999999;
}

.drawer-header {
  .txt {
    color: #fff;
    font-size: 0.8rem;
  }
}

.drawer-open {
  .drawer-header {
    .txt {
      display: none;
    }
  }
}



.drawer-hamburger:hover {
  cursor: pointer;
}
.drawer-hamburger .txt {
  color: #fff;
  font-size: 0.7em;
  position: absolute;
  bottom: 5px;
  left: 0;
  width: 100%;
  text-align: center;
}
.drawer-hamburger-icon {
  position: relative;
  display: block;
  margin-top: 2px;
}
.drawer-hamburger-icon, .drawer-hamburger-icon:before,
.drawer-hamburger-icon:after {
  height: 2px;
  width: 100%;
  background-color: #FFF;
  -webkit-transition: all 0.6s cubic-bezier(0.19, 1, 0.22, 1);
  -o-transition: all 0.6s cubic-bezier(0.19, 1, 0.22, 1);
  transition: all 0.6s cubic-bezier(0.19, 1, 0.22, 1);
}
.drawer-open .drawer-hamburger-icon,
.drawer-open .drawer-hamburger-icon:before,
.drawer-open .drawer-hamburger-icon:after {
  background-color: colors.$hamburger-background;//_colorsから変更
}
.drawer-hamburger-icon:before, .drawer-hamburger-icon:after {
  position: absolute;
  content: " ";
  top: -10px;
  left: 0;
}
.drawer-hamburger-icon:after {
  top: -5px;
}
.drawer-open .drawer-hamburger-icon {
  background-color: transparent;
}
.drawer-open .drawer-hamburger-icon:before,
.drawer-open .drawer-hamburger-icon:after {
  top: -2px;
}
.drawer-open .drawer-hamburger-icon:before {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
.drawer-open .drawer-hamburger-icon:after {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.drawer-default {
  -webkit-box-shadow: inset 0 0px 0px #303030;
  -webkit-box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0.5);
  box-shadow: inset 0 0px 0px #303030;
  box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0.5);
}
.drawer-default + .drawer-overlay {
  background-color: #fff;
}
.drawer-default li,
.drawer-default a,
.drawer-default .drawer-brand {
  position: relative;
  display: block;
}
.drawer-open .drawer-default li {
  width: 90%;
  margin: 0 auto;
  border-top: 1px solid #e2e2e2;
  opacity: 0;
  -webkit-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  transform: translateX();
  -webkit-animation: menu_anime 0.5s ease 0.25s 1 forwards;
  animation: menu_anime 0.5s ease 0.25s 1 forwards;
}

.drawer-open .drawer-default li.logo {
  width: 46%;
  margin: 10% auto;
  border-top: none;
}

.drawer-open .drawer-default li.last {
  border-bottom: 1px solid #e2e2e2;
  margin-bottom: 10%;
}

.drawer-open .drawer-default li.other_link {
  width: 42%;
  display: inline-block;
  border-top: none;
}

.drawer-open .drawer-default li.other_link a {
  text-align: center;
  font-size: 11px;
  font-weight: 300;
}

.drawer-open .drawer-default li.other_link a.blank p {
  padding-right: 16px;
  background: url(../img/cmn/icon_blank.png) right 2px no-repeat;
  background-size: 10px auto;
  display: inline;
}

.drawer-open .drawer-default li .sub_list {
  height: auto;
}

.drawer-open .drawer-default li .sub_list li {
  width: 100%;
}

.drawer-open .drawer-default li .sub_list li a::before {
  content: "- ";
}

.drawer-open .drawer-default li .sub_list li a p {
  display: inline;
}

.drawer-open .drawer-default li.btn_cv,
.drawer-open .drawer-default li.btn_tel {
  border: none;
  margin-bottom: 5%;
}

.drawer-open .drawer-default li.btn_cv a {
  background: colors.$menu_cv;
  color: #FFF;
  text-align: center;
  font-size: 16px;
  padding: 8% 0;
}

.drawer-open .drawer-default li.btn_tel a {
  background: #f5e3e0;
  color: #393939;
  text-align: center;
  line-height: 1.0;
  padding: 7% 0;
}

.drawer-open .drawer-default li.btn_tel a span {
  font-size: 20px;
  margin-bottom: 5px;
}


@-webkit-keyframes menu_anime {
  50% {
  opacity: 0;
  }
  100% {
  opacity: 1;
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  }
  }
  @keyframes menu_anime {
  50% {
  opacity: 0;
  }
  100% {
  opacity: 1;
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  }
}

.drawer-default a {
  text-decoration: none;
  color: #171717;
  font-size: 13px;
  text-align: center;
  padding: 5% 5%;
  font-weight: 600;
  line-height: 1.0;
}

.drawer-default .logo {
  width: 100%;
  margin-bottom: 5%;
  background: #FFF;
}

.drawer-default .logo a img {
  width: 100%;
}

.drawer-default a .inner {
  margin: 0 auto;
  position: relative;
}
.drawer-default a span {
  display: inline-block;
}

@media print, screen and (max-width: 760px) {
.drawer-default ul {
  height: 800px;
  text-align: center;
}
}
.drawer-default a:hover, .drawer-default a:focus {
  background: rgba(218,164,163,.3);
}
.drawer-default .drawer-brand a {
  padding: 20px 15px;
  color: #FFF;
  font-size: 22px;
}
.drawer-default .drawer-brand a:hover {
  opacity: 1.0;
}
.drawer-default .drawer-brand > img {
  display: block;
}
.drawer-default .drawer-footer {
  position: relative;
  padding: 0 15px;
  height: 50px;
  line-height: 50px;
  background-color: transparent;
}
.drawer-default .drawer-footer span:before,
.drawer-default .drawer-footer span:after {
  content: " ";
  display: block;
}
.drawer-default > ul {
  width: 100%;
  height: 1000px;
  margin: 0 auto 54px;
  padding: 0;
  list-style: none;
}
.drawer-default .drawer-menu-item {
  padding: 15px 15px 0;
  font-size: 18px;
  color: #555;
}
.drawer-default .drawer-menu-item.disabled a {
  color: #333;
}
.drawer-default .drawer-menu-item.disabled a:hover,
.drawer-default .drawer-menu-item.disabled a:focus {
  cursor: not-allowed;
  color: #333;
  background-color: transparent;
}
.drawer-default .drawer-menu-item a {
  /* color: #888; */
  color: #111;
}
.drawer-default .drawer-menu-item a:hover,
.drawer-default .drawer-menu-item a:focus {
  color: #fff;
  background-color: transparent;
}
.drawer-default .drawer-menu-item a img {
  max-width: none;
}
.drawer-default .drawer-submenu {
  margin-bottom: 20px;
}
.drawer-default .drawer-submenu:last-child {
  margin-bottom: 0;
}
.drawer-default .drawer-submenu-item {
  padding: 0;
}
.drawer-default .drawer-submenu-item a {
  height: 50px;
  line-height: 50px;
  font-size: 14px;
}
.drawer-default .drawer-submenu-item a:hover,
.drawer-default .drawer-submenu-item a:focus {
  color: #fff;
}
.drawer-default .dropdown-menu {
  position: absolute;
  display: none;
  z-index: 5000;
  background-color: #222;
  border: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.drawer-default .dropdown-menu > li > a {
  padding: 0 !important;
}
.drawer-default .dropdown.open > .dropdown-menu {
  position: static;
  display: block;
  float: none;
  width: auto;
}

@media (max-width: 767px) {
.drawer-default a:hover, .drawer-default a:focus {
  color: #888 !important;
  text-decoration: none;
}
}
.drawer-navbar-default {
  background-color: #222;
  -webkit-box-shadow: inset 0 0px 0px #000000;
  -webkit-box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0.5);
  box-shadow: inset 0 0px 0px #000000;
  box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0.5);
}
.drawer-navbar-default + .drawer-overlay {
  background-color: #fff;
}
.drawer-navbar-default li,
.drawer-navbar-default a,
.drawer-navbar-default .drawer-brand {
  position: relative;
  display: block;
}
.drawer-navbar-default a {
  text-decoration: none;
  outline: 0;
}
.drawer-navbar-default a:hover,
.drawer-navbar-default a:focus {
  text-decoration: none;
}
.drawer-navbar-default .drawer-brand a {
  padding: 20px 15px;
  color: #fff;
  font-size: 22px;
}
.drawer-navbar-default .drawer-brand a:hover,
.drawer-navbar-default .drawer-brand a:focus {
  color: #555;
}
.drawer-navbar-default .drawer-brand > img {
  display: block;
}
.drawer-navbar-default .drawer-footer {
  position: relative;
  padding: 0 15px;
  height: 50px;
  line-height: 50px;
  background-color: transparent;
}
.drawer-navbar-default .drawer-footer span:before,
.drawer-navbar-default .drawer-footer span:after {
  content: " ";
  display: block;
}
.drawer-navbar-default ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.drawer-navbar-default .drawer-menu.drawer-navbar-right {
  float: none;
}
.drawer-navbar-default .drawer-menu-item {
  padding: 15px 15px 0;
  font-size: 18px;
  color: #555;
}
.drawer-navbar-default .drawer-menu-item.disabled a {
  color: #333;
}
.drawer-navbar-default .drawer-menu-item.disabled a:hover,
.drawer-navbar-default .drawer-menu-item.disabled a:focus {
  color: #333;
  background-color: transparent;
  cursor: not-allowed;
}
.drawer-navbar-default .drawer-menu-item a {
  color: #888;
}
.drawer-navbar-default .drawer-menu-item a:hover,
.drawer-navbar-default .drawer-menu-item a:focus {
  color: #fff;
  background-color: transparent;
}
.drawer-navbar-default .drawer-menu-item a img {
  max-width: none;
}
.drawer-navbar-default .drawer-submenu {
  margin-bottom: 20px;
}
.drawer-navbar-default .drawer-submenu:last-child {
  margin-bottom: 0;
}
.drawer-navbar-default .drawer-submenu-item {
  padding: 0;
}
.drawer-navbar-default .drawer-submenu-item a {
  height: 50px;
  line-height: 50px;
  font-size: 14px;
}
.drawer-navbar-default .drawer-submenu-item a:hover,
.drawer-navbar-default .drawer-submenu-item a:focus {
  color: #fff;
}
.drawer-navbar-default .dropdown-menu {
  position: absolute;
  display: none;
  z-index: 5000;
  top: 100%;
  left: 0;
  background-color: #222;
  border: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.drawer-navbar-default .dropdown-menu > li > a {
  padding: 0 !important;
}
.drawer-navbar-default .dropdown.open > .dropdown-menu {
  display: block;
}

@media (max-width: 767px) {
.drawer-navbar-default .container {
  padding-left: 0;
  padding-right: 0;
}
.drawer-navbar-default .drawer-menu-item a:hover,
.drawer-navbar-default .drawer-menu-item a:focus {
  color: #888;
  text-decoration: none;
}
.drawer-navbar-default .drawer-submenu {
  margin-bottom: 20px;
}
.drawer-navbar-default .drawer-submenu:last-child {
  margin-bottom: 0;
}
.drawer-navbar-default .drawer-submenu-item a:hover,
.drawer-navbar-default .drawer-submenu-item a:focus {
  color: #ddd;
}
.drawer-navbar-default .dropdown.open > .dropdown-menu {
  position: static;
  float: none;
  width: auto;
}
}
.drawer-dropdown .caret, .drawer-dropdown-hover .caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px solid;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  -webkit-transition: -webkit-transform 0.2s ease, opacity 0.2s ease;
  -o-transition: -o-transform 0.2s ease, opacity 0.2s ease;
  transition: transform 0.2s ease, opacity 0.2s ease;
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
}
.drawer-dropdown.open .caret, .drawer-dropdown-hover.open .caret {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.dropdown-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 990;
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  clip: auto;
}

@media (min-width: 767px) {
  .drawer-responsive.drawer-left .drawer-toggle,
  .drawer-responsive.drawer-right .drawer-toggle,
  .drawer-navbar.drawer-left .drawer-toggle,
  .drawer-navbar.drawer-right .drawer-toggle,
  .drawer-navbar.drawer-top .drawer-toggle {
    display: none;
    visibility: hidden;
  }
  .drawer-responsive.drawer-left .drawer-main {
    left: 0;
    display: block;
    -webkit-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none;
  }
  .drawer-responsive.drawer-right .drawer-main {
    right: 0;
    display: block;
    -webkit-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none;
  }
}

@media (min-width: 768px) {
  body.drawer-navbar.drawer-fixed {
    padding-top: 50px;
  }
  .drawer-responsive {
    background-color: #222;
  }
  .drawer-responsive .drawer-overlay {
    background-color: #fff;
  }
  .drawer-responsive .container {
    max-width: 618px;
  }
  .drawer-responsive.drawer-left .drawer-main,
  .drawer-responsive.drawer-right .drawer-main {
    position: absolute;
    width: 150px !important;
    height: auto !important;
    overflow: visible !important;
  }
  .drawer-responsive.drawer-left .drawer-main {
    float: left;
  }
  .drawer-responsive.drawer-left .drawer-overlay {
    margin-left: 150px;
  }
  .drawer-responsive.drawer-right .drawer-main {
    float: right;
  }
  .drawer-responsive.drawer-right .drawer-overlay {
    margin-right: 150px;
  }
  .drawer-navbar.drawer-left .drawer-main,
  .drawer-navbar.drawer-right .drawer-main,
  .drawer-navbar.drawer-top .drawer-main {
    position: relative;
  }
  .drawer-navbar.drawer-static.drawer-left .drawer-main,
  .drawer-navbar.drawer-static.drawer-right .drawer-main,
  .drawer-navbar.drawer-static.drawer-top .drawer-main {
    z-index: 5000;
  }
  .drawer-navbar.drawer-fixed.drawer-left .drawer-main,
  .drawer-navbar.drawer-fixed.drawer-right .drawer-main,
  .drawer-navbar.drawer-fixed.drawer-top .drawer-main {
    position: fixed;
    z-index: 9999999999;
  }
  .drawer-navbar.drawer-left .drawer-main,
  .drawer-navbar.drawer-right .drawer-main,
  .drawer-navbar.drawer-top .drawer-main {
    display: block;
    top: 0;
    left: 0;
    width: 100% !important;
    height: auto !important;
    overflow: visible !important;
    -webkit-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none;
  }
  .drawer-navbar-default {
    position: relative;
    background-color: #fff;
    border-bottom: 1px solid #ccc;
  }
  .drawer-navbar-default li,
  .drawer-navbar-default a,
  .drawer-navbar-default .drawer-brand {
    float: left;
  }
  .drawer-navbar-default a {
    padding: 0 15px;
    height: 50px;
    line-height: 50px;
    font-size: 14px;
  }
  .drawer-navbar-default .drawer-brand a {
    padding: 0 15px;
    color: #555;
    font-size: 90%;
  }
  .drawer-navbar-default .drawer-brand a:hover,
  .drawer-navbar-default .drawer-brand a:focus {
    color: #333;
    background-color: #eee;
  }
  .drawer-navbar-default .drawer-footer {
    display: none;
  }
  .drawer-navbar-default .drawer-menu.drawer-navbar-right {
    float: right;
  }
  .drawer-navbar-default .drawer-menu.drawer-navbar-right .dropdown-menu {
    right: 0;
    left: auto;
  }
  .drawer-navbar-default .drawer-menu-item {
    padding: 0;
    font-size: 14px;
  }
  .drawer-navbar-default .drawer-menu-item a:hover,
  .drawer-navbar-default .drawer-menu-item a:focus {
    color: #888;
    background-color: #eee;
  }
  .drawer-navbar-default .dropdown-menu {
    -webkit-box-shadow: 0 6px 12px #000000;
    -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    box-shadow: 0 6px 12px #000000;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  }
  .drawer-navbar-default .dropdown-menu > li {
    float: none;
  /* for IE7+ */ }
  .drawer-navbar-default .dropdown-menu > li > a {
    width: 160px;
    padding: 0 15px !important;
    /* conflict by cooker */
    color: #fff;
    background-color: #444;
    border-bottom: 1px solid #555;
    opacity: 0.9;
    filter: alpha(opacity=90);
  }
  .drawer-navbar-default .dropdown-menu > li > a:hover,
  .drawer-navbar-default .dropdown-menu > li > a:focus {
    color: #999;
    background-color: #333;
  }
}

@media (min-width: 1200px) {
  .drawer-responsive .container {
    max-width: 768px;
  }
  .drawer-responsive.drawer-left .drawer-main,
  .drawer-responsive.drawer-right .drawer-main {
    width: 280px !important;
  }
  .drawer-responsive.drawer-left .drawer-overlay {
    margin-left: 280px;
  }
  .drawer-responsive.drawer-right .drawer-overlay {
    margin-right: 280px;
  }
}

@media print, screen and (max-width: 760px) {
  .drawer-open .drawer-default li.logo {
    width: 35%;
    margin: 0 auto;
    border-top: none;
  }
}
