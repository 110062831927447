@charset "utf-8";

@use "../_colors";
@use "../_text";



/* ------------------------------------

page_index c-section01

------------------------------------ */
